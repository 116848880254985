import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3140a13527e02476fea9e678a2df3946@o376420.ingest.sentry.io/4506503753891840",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()